import { gql, Query } from 'apollo-angular';
import { Injectable } from '@angular/core';

import { ContactDto } from '../../dtos/contact.dto';

@Injectable({
  providedIn: 'root',
})
export class ContactGQL extends Query<
  { contact: ContactDto },
  { contactId: string }
> {
  override document = gql`
    query GetContact($contactId: String) {
      contact {
        getContactById(contactId: $contactId) {
          data {
            contactID
            contactNumber
            firstName
            lastName
            emailID
          }
          isSuccess
        }
      }
    }
  `;
}
