import { Action, State, StateContext } from '@ngxs/store';
import { switchMap, take, tap } from 'rxjs';
import { Injectable } from '@angular/core';

import { RouteStoreService } from '@customer-portal/router';

import { PreferenceModel } from '../models';
import { PreferenceMapperService, PreferenceService } from '../services';
import {
  LoadPreference,
  LoadPreferenceSuccess,
  SavePreference,
} from './preference.actions';

export interface PreferenceStateModel {
  contactId: string | null;
  preferenceItems: PreferenceModel[];
}

const defaultState: PreferenceStateModel = {
  contactId: null,
  preferenceItems: [],
};

@State<PreferenceStateModel>({
  name: 'preferences',
  defaults: defaultState,
})
@Injectable()
export class PreferenceState {
  constructor(
    private preferenceService: PreferenceService,
    private routeStoreService: RouteStoreService,
  ) {}

  @Action(LoadPreference)
  loadPreference(
    ctx: StateContext<PreferenceStateModel>,
    { objectName, objectType, pageName }: LoadPreference,
  ) {
    return this.routeStoreService.getQueryParamByKey('contactId').pipe(
      take(1),
      switchMap((contactId) =>
        this.preferenceService
          .getPreference(contactId, objectType, objectName, pageName)
          .pipe(
            tap((result) => {
              ctx.patchState({
                contactId,
              });

              const preference =
                PreferenceMapperService.mapToPreferenceModel(result);

              if (preference) {
                ctx.dispatch(new LoadPreferenceSuccess(preference));
              }
            }),
          ),
      ),
    );
  }

  @Action(LoadPreferenceSuccess)
  loadPreferenceSuccess(
    ctx: StateContext<PreferenceStateModel>,
    { preference }: LoadPreferenceSuccess,
  ) {
    const state = ctx.getState();
    const preferenceItems = state.preferenceItems
      .filter(
        (item) =>
          item.pageName !== preference.pageName ||
          item.objectType !== preference.objectType ||
          item.objectName !== preference.objectName,
      )
      .concat(preference);

    ctx.patchState({
      preferenceItems,
    });
  }

  @Action(SavePreference)
  savePreference(
    ctx: StateContext<PreferenceStateModel>,
    { preference }: SavePreference,
  ) {
    const { contactId } = ctx.getState();
    const dto = PreferenceMapperService.mapToPreferenceDto(
      preference,
      contactId,
    );

    return this.preferenceService.savePreferences(dto);
  }
}
