import { Selector } from '@ngxs/store';

import { ContactState, ContactStateModel } from './contact.state';

export class ContactSelectors {
  @Selector([ContactState])
  static contact(state: ContactStateModel) {
    return state.contact;
  }
}
