import { toSignal } from '@angular/core/rxjs-interop';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';

import { ContactSelectors } from './contact.selectors';
import { ContactModel } from '../models/contact.model';
import { GetContact } from './contact.actions';

export class ContactStoreService {
  @Select(ContactSelectors.contact)
  private contact$!: Observable<ContactModel>;

  contact = toSignal(this.contact$);

  @Dispatch()
  getContactById = () => new GetContact();
}
