import { Injectable } from '@angular/core';
import { map } from 'rxjs';

import { ContactGQL } from '../graphql/queries/contact.graphql';

@Injectable({ providedIn: 'root' })
export class ContactService {
  constructor(private readonly contactGQL: ContactGQL) {}

  getContactById(contactId: string) {
    return this.contactGQL
      .watch({
        contactId,
      })
      .valueChanges.pipe(map((results) => results.data.contact));
  }
}
