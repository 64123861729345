import { ContactDto } from '../dtos/contact.dto';
import { ContactModel } from '../models/contact.model';

export class ContactMapperService {
  static mapToContactModel(dto: ContactDto): ContactModel {
    const { data } = dto.getContactById;

    return {
      contactID: data.contactID,
      contactNumber: data.contactNumber,
      emailID: data.emailID,
      firstName: data.firstName,
      lastName: data.lastName,
    };
  }
}
