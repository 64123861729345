import { Action, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { switchMap, take, tap } from 'rxjs';

import { RouteStoreService } from '@customer-portal/router';

import { ContactModel } from '../models/contact.model';
import { ContactService } from '../services/contact.service';
import { GetContact } from './contact.actions';
import { ContactDto } from '../dtos/contact.dto';
import { ContactMapperService } from '../services/contact-mapper.service';

export interface ContactStateModel {
  contact: ContactModel | null;
}

const defaultState: ContactStateModel = {
  contact: null,
};

@State<ContactStateModel>({
  name: 'contact',
  defaults: defaultState,
})
@Injectable()
export class ContactState {
  constructor(
    private readonly contactService: ContactService,
    private routeStoreService: RouteStoreService,
  ) {}

  @Action(GetContact)
  getAllUsers(ctx: StateContext<ContactStateModel>) {
    return this.routeStoreService.getQueryParamByKey('contactId').pipe(
      take(1),
      switchMap((contactId) =>
        this.contactService.getContactById(contactId).pipe(
          tap((contactDto: ContactDto) => {
            ctx.patchState({
              contact: ContactMapperService.mapToContactModel(contactDto),
            });
          }),
        ),
      ),
    );
  }
}
